import { GetvalidatePasswordResetToken, PostCreateUser, PostforgotPassword, PostPasswordRecovery, PostRecoveryPassword, UsuarioModel } from "@/models/UsuarioModel";
import ApiService from "./ApiService";



 export const usuarioList = async ( postData: any, page = 1): Promise<Array<UsuarioModel>> => {
    const { data } = await ApiService.post(`/usuarioList?page=${page}` , postData);
    return data;
 };

export const deleteUsuario = async (postData) => {
   const { data } = await ApiService.delete(`/usuariodelete/${postData}`);
   return data;
};

export const getView = async (userId) => {
   const { data } = await ApiService.get(`/usuarioview/${userId}`);
   return data;
};

export const getAdd = async (postData: PostCreateUser): Promise<{message: string, error: boolean}> => {
   const { data } = await ApiService.post(`/usuarioadd`, postData as object );
   return data;
};

export const filtroList = async (postData: any) => {
   const { data } = await ApiService.get(`/usuario_filtroslist/${postData}` );
   return data;
};

export const storeFiltroUsuario = async (postData: any) => {
   const { data } = await ApiService.post(`/storeFiltroUsuario`,postData );
   return data;
};

export const deleteFiltroUsuario = async (postData) => {
   const { data } = await ApiService.delete(`/deleteFiltroUsuario/${postData}`);
   return data;
};

export const enviarNovaSenha = async (postData : PostRecoveryPassword) : Promise<{message: string}> => {
   const { data } = await ApiService.post(`/enviarNovaSenha`, postData as object);
   return data;
}

export const verificaEmail = async (postData) => {
   const { data } = await ApiService.post(`/verifyExistEmail`, postData);
   return data;
}

export const gerarNovaSenha = async (postData) => {
   const { data } = await ApiService.post(`/gerarTokenParaCriarSenha`, postData);
   return data;
}

export const passwordRecovery = async (postData : PostPasswordRecovery) : Promise<{message : string , error: boolean}> => {
   const { data } = await ApiService.post(`/novaSenha`, postData as object);
   return data;
}

export const forgotPassword = async (postData : PostforgotPassword) : Promise<{message : string , error: boolean}> => {
   const { data } = await ApiService.post(`/forgotPassword`, postData as object);
   return data;
}

export const validatePasswordResetToken = async (token : string) : Promise<GetvalidatePasswordResetToken> => {
   const { data } = await ApiService.get(`/validatePasswordResetToken/${token}`);
   return data;
}

export default {usuarioList, deleteUsuario, getAdd, getView, filtroList, storeFiltroUsuario, deleteFiltroUsuario , passwordRecovery};